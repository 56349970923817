<template>

    <b-overlay
      :show="is_loading"
      spinner-variant="primary"
      variant='transparent'
      style="min-height: 50vh;"
    >
          
        <div v-if="contract">
            <showAt breakpoint="small">
                <b-row>
                    <b-col cols="12">
                        <payment-mobile-view  v-if="contract.status == 0" :contract="contract" @showPaymentModal="showPaymentModal = true" />
                        <contract-card-mobile-widget :contract="contract" />
                    </b-col>
                    <b-col cols="12" style="padding-top: 16px;">
                                                
                        <b-tabs pills class="with-paddings">
                            <b-tab v-if="contract.type === 2" :title="$t('statistics.tabs.miners')">
                                <miners-view :contract="contract" />
                            </b-tab>
                            <b-tab :title="$t('statistics.tabs.mining-detail')">
                                <detalization-mobile-view :contract="contract" />
                            </b-tab>
                            <b-tab :title="$t('statistics.tabs.cart-profit')">
                                <b-card no-body>
                                    <graphic-view :contract="contract" />
                                </b-card>
                            </b-tab>
                           
                        </b-tabs>
                    </b-col>
                </b-row>
            </showAt>
            <showAt breakpoint="medium">
                <b-row>
                     <b-col cols="12">
                        <contract-card-tablet-widget :contract="contract" />
                        <payment-tablet-view  v-if="contract.status == 0" :contract="contract" @showPaymentModal="showPaymentModal = true" />
                    </b-col>
                    <b-col cols="12" style="padding-top: 16px;">
                                                
                        <b-tabs pills >
                            <b-tab v-if="contract.type === 2" :title="$t('statistics.tabs.miners')">
                                    <miners-view :contract="contract" />
                            </b-tab>
                            <b-tab :title="$t('statistics.tabs.mining-detail')">
                                <detalization-view :contract="contract" />
                            </b-tab>
                            <b-tab :title="$t('statistics.tabs.cart-profit')">
                                <b-card no-body>
                                    <graphic-view :contract="contract" />
                                </b-card>
                            </b-tab>
                           
                        </b-tabs>
                    </b-col>
                </b-row>
            </showAt>
            <showAt breakpoint="large">
                <div>
                    <div v-if="contract" class="d-flex" style="margin-bottom: 24px;">
                        
                        <span style="font-size: 20px; font-weight: 600;">{{$t('contracts.contract-identificator')}}{{ $route.params.id }}</span>
                        
                        <b-badge class="mt-auto mb-auto ml-1" v-if="contract.status === 0" variant="danger">{{$t('contracts.statuses.unpaid')}}</b-badge>
                        <b-badge class="mt-auto mb-auto ml-1" v-if="contract.status === 1" >{{$t('contracts.statuses.working')}}</b-badge>
                        <b-badge class="mt-auto mb-auto ml-1" v-if="contract.status === 2" variant="primary">{{$t('contracts.statuses.payment-received')}}</b-badge>
                        <b-badge class="mt-auto mb-auto ml-1 badge-start-view" v-if="contract.status === 3"  >{{$t('contracts.statuses.start')}} {{ Utils.daysAgo( contract.start_at ) }}</b-badge>
                        <b-badge class="mt-auto mb-auto ml-1" v-if="contract.status === 4" variant="danger">{{$t('contracts.statuses.finish')}}</b-badge>
                        <b-badge class="mt-auto mb-auto ml-1" v-if="contract.status === 6" variant="secondary">{{$t('contracts.statuses.archive')}}</b-badge>
                        <b-badge class="mt-auto mb-auto ml-1" v-if="contract.status === 7" variant="secondary">{{$t('contracts.statuses.pause')}}</b-badge>
                        
                    </div>
                    <div class="d-flex">
                        <div style="width: 272px;">
                            <contract-card-desktop-widget :contract="contract" />
                        </div>
                        <div style="padding: 0 32px; width: calc(100% - 272px);">
                            <payment-desktop-view  v-if="contract.status == 0 || contract.status == 2" :contract="contract" @showPaymentModal="showPaymentModal = true" />
                            <b-tabs pills >
                                <b-tab v-if="contract.type === 2" :title="$t('statistics.tabs.miners')">
                                    <miners-view :contract="contract" />
                                </b-tab>
                                <b-tab :title="$t('statistics.tabs.mining-detail')">
                                    <detalization-view :contract="contract" />
                                </b-tab>
                                <b-tab :title="$t('statistics.tabs.cart-profit')">
                                    <b-card no-body>
                                        <graphic-view :contract="contract" />
                                    </b-card>
                                </b-tab>
                            </b-tabs>
                        </div>
                    </div>
                </div>
                
            </showAt>
            
            
            <b-modal v-if="contract.status == 0 && contract.invoice" v-model="showPaymentModal" centered hide-footer :title="$t('contracts.modal.contract-payment')">
                <div>
                    <div >
                        <p class="text-muted" style="font-size: 13px; line-height: 20px;">{{$t('contracts.modal.text-transfer-btc')}}</p>
                        <p v-if="contract.is_anonymous && contract.type == 1" class="text-muted" style="font-size: 13px; line-height: 20px;"> {{$t('contracts.modal.based-payment-contract-offer')}}
                            <b-link to="/documents?show=public-offer&type=1" target='_blank'>{{$t('contracts.modal.public-offer')}}</b-link>.
                        </p>
                        <p v-if="contract.is_anonymous && contract.type == 2" class="text-muted" style="font-size: 13px; line-height: 20px;"> {{$t('contracts.modal.based-payment-contract-offer')}}
                            <b-link to="/documents?show=public-offer&type=2" target='_blank'>{{$t('contracts.modal.public-offer')}}</b-link>.
                        </p>
                        <p v-else class="text-muted" style="font-size: 13px; line-height: 20px;"> {{$t('contracts.modal.based-payment-contract-official')}} </p>
                    </div>
                    
                    <div class="data-form">
                        <b-form-group>
                            <div class="d-flex justify-content-between">
                                <div style="flex-grow: 1;">
                                    <span class="text-muted" style="font-size: 12px;">Сумма к оплате</span>
                                    <p class="m-0">{{ (contract.invoice.btc - contract.invoice.unconfirmed - contract.invoice.received).decimalBtcMoney() }}</p>
                                </div>
                                <div class="mt-auto mb-auto">
                                    <p class="m-0 text-muted">BTC</p>
                                </div>
                            </div>
                        </b-form-group>
                    </div>

                    <div class="d-flex">

                        <div style="margin-right: 26px;">
                            <b-form-group class="form-group-with-label">
                                <div class="d-flex justify-content-between"> 
                                    <div ref="container" class="wordwrap">
                                        <span class="text-muted" style="font-size: 12px;">{{$t('contracts.modal.payment-address')}}</span>
                                        <p style="font-size: 16px; font-weight: 500; margin: 0; word-break: break-all;">{{ contract.invoice.identificator }}</p>
                                    </div>
                                    <div 
                                        v-ripple 
                                        @click="copyBitcoinAddress"
                                        style="margin: auto 12px; margin-right: 0;">
                                        <feather-icon 
                                            icon="CopyIcon" 
                                            size="20" 
                                            class="text-muted cursor-pointer" 
                                        />
                                    </div>
                                </div>
                            </b-form-group>
                        </div>

                        <div>
                            <qrcode :value="`${contract.invoice.identificator}`" :options="{ width: 80, margin: 0 }"></qrcode>
                        </div>

                    </div>
                    
                    <div>
                       <p class="text-muted" style="font-size: 13px; line-height: 20px;">{{$t('contracts.modal.payment-processing')}}</p>
                    </div>
                    
                    <b-row style="margin-top: 12px;">
                        <b-col>
                           <b-button :to="{ name: 'user-wallet', query: { act: 'output', to: contract.invoice.identificator, amount: (contract.invoice.btc - contract.invoice.unconfirmed - contract.invoice.received).decimalBtcMoney() } }" replace size="lg" pill variant="primary" block style='padding-left:0; padding-right: 0;'>{{$t('contracts.modal.pay-from-wallet')}}</b-button>
                        </b-col>
                    </b-row>

                </div>
            </b-modal>
            
        </div>
        
        
        
        
        
    </b-overlay>
    
</template>

<script>
    
    import ContractCardMobileWidget from "./widgets/card.mobile"
    import ContractCardTabletWidget from "./widgets/card.tablet"
    import ContractCardDesktopWidget from "./widgets/card.desktop"

    import detalizationView from "./tabs/detalization"
    import detalizationMobileView from "./tabs/detalization.mobile"
    
    import graphicView from "./tabs/graphic"

    import paymentDesktopView from "./widgets/payment.desktop"
    import paymentTabletView from "./widgets/payment.tablet"
    import paymentMobileView from "./widgets/payment.mobile"

    import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

    import MinersView from "./tabs/miners"
    import Utils from "@/modules/utils/"
    
    export default {

        data() {
            return {
                Utils,
                message: "Copy Me!",
                contract: null,
                is_loading: false,
                showPaymentModal: false
            }
        },
        methods: {

            copyBitcoinAddress( args ) {
                
                let container = this.$refs.container;
                this.$copyText(this.contract.invoice.identificator, container);
                
                this.$toast({
                    component: ToastificationContent,
                    props: {
                      title: this.$t('toasts.titles.copy'),
                      text: this.$t('toasts.text.btc-address'),
                      icon: 'CheckCircleIcon',
                      variant: 'success',
                    }
                });  
            },

        },
        
        computed: {
            address() {
                if( this.contract.invoice ) {
                    return this.contract.invoice.identificator;
                }
            }
        },
        
        components: {
            ContractCardMobileWidget,
            ContractCardTabletWidget,
            ContractCardDesktopWidget,
            
            detalizationView,
            detalizationMobileView,
            
            graphicView,
            
            paymentDesktopView,
            paymentTabletView,
            paymentMobileView,
            
            MinersView
            
        },
        watch: {

        },
        mounted() {

            this.is_loading = true;

            this.$request.get("contracts.get", {
                id: this.$route.params.id
            }).then( result => {
                
                this.contract = result;
                this.btc_address = result.btc_address;
                
                this.is_loading = false;
            });

        }

    }

</script>