<template>

    <div>
        
        <div v-if=" contract.invoice.type === 'btc' && (contract.invoice.received < contract.invoice.btc && contract.invoice.unconfirmed + contract.invoice.received >= contract.invoice.btc) " class="primary-card-view" style='margin-bottom: 16px;'>
            <div>
                <p style="font-size: 16px; font-weight: 600; margin-bottom: 8px;">{{$t('contracts.statuses.payment-received')}}</p>
                <p style="font-size: 12px; margin-bottom: 0;">{{$t('contracts.payment.activated-automatically')}}</p>
            </div>
        </div>
        <div v-else-if=" contract.invoice.type === 'btc' && ((contract.invoice.unconfirmed + contract.invoice.received <= contract.invoice.btc && (contract.invoice.unconfirmed > 0 || contract.invoice.received > 0))) " class="danger-card-view" style='margin-bottom: 16px;'>
            <div>
                <p style="font-size: 16px; font-weight: 600; margin-bottom: 8px;">{{$t('contracts.payment.not-enough-funds')}}</p>
                <p style="font-size: 12px; margin-bottom: 0;">{{$t('contracts.payment.received-partial')}} {{ contract.invoice.unconfirmed + contract.invoice.received }} BTC,<br>{{$t('contracts.payment.pay-extra')}} {{ (contract.invoice.btc - contract.invoice.unconfirmed - contract.invoice.received).toFixed(8) }} BTC.</p>
                <b-button @click="makePayment" variant="danger" style="margin-top: 16px; height: 44px; width: 170px; border-radius: 10px;">{{$t('contracts.btns.pay-extra')}}</b-button>
            </div>
        </div>
        <div v-else class="danger-card-view" style='margin-top: 16px; margin-bottom: 16px;'>

            <div class="d-flex justify-content-between">
                <i18n path="contracts.payment.need-to-pay" tag="p" style="font-size: 16px; font-weight: 600;">
                    <br>
                </i18n>
                <b-img src="@/@core/assets/images/bell.png" fluid width="100px" style="position: absolute; right: 25px; top: -20px;" />
            </div>

            <p style="font-size: 12px;">{{$t('contracts.payment.order-avoid-termination')}} {{ contract.investments.formatMoney(0,' ',' ') }} $
            </p>
            
            <div class="d-flex align-items-center">
                <div class="text-danger" style="font-size: 12px; margin-right: 5px;">{{$t('contracts.payment.time-left-to-pay')}}: </div>
                <countdown :end-time="until">
                    <template
                      v-slot:process="countdown">
                        <div class="d-flex counter">
                            <div class="bg-white text-danger font-small-2" style="width: 22px; height: 22px; line-height: 22px;">{{ countdown.timeObj.h }}</div>
                                <span class="text-danger">:</span>
                            <div class="bg-white text-danger font-small-2" style="width: 22px; height: 22px; line-height: 22px;">{{ countdown.timeObj.m }}</div>
                                <span class="text-danger ">:</span>
                            <div class="bg-white text-danger font-small-2" style="width: 22px; height: 22px; line-height: 22px;">{{ countdown.timeObj.s }}</div>
                        </div>
                    </template>
                </countdown>
            </div>

            <b-button @click="makePayment" variant="danger" size="lg" block style="margin-top: 16px;">{{$t('contracts.btns.pay')}}</b-button>

        </div>
    </div>
    
</template>

<script>

    export default {

        data() {
            return {}
        },
        props: {
            contract: {
                type: Object
            }
        },
        methods: {
            makePayment( args ) {
                
                if( this.contract.invoice.type === 'card' ) {
                    // window.open(`${process.env.VUE_APP_BASE_URL}payment-make/${this.contract.invoice.identificator}`, "", "width=720,height=720,resizable=yes,scrollbars=yes,status=yes")
                    window.open(`${process.env.VUE_APP_BASE_URL}payment-make/${this.contract.invoice.identificator}`, '_blank')
                } else {
                    this.$emit("showPaymentModal");
                }
                 
            },
        },
        
        computed: {
            until() {
                var result = new Date( this.contract.created_at * 1000 );
                result.setHours(result.getHours() + 1);
                return result.getTime();
                return new Intl.DateTimeFormat('ru-RU', {
                    day: "2-digit",
                    month: 'long',
                    year: "numeric"
                }).format(result);
            }
        },
        
        components: {

        },
        watch: {

        },
        mounted() {
            var mydate = new Date( this.contract.start_at );
            
        }

    }

</script>