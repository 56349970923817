<template>
    <div>
        <showAt breakpoint="small">
            <div style="padding: 0 16px;">
        
                <b-card v-for="(item, index) in contract.miners" @click="onSelect({ item: item })" :key="`contract:${item.id}:${index}`" no-body style=" border-radius: 16px !important;">
                    <b-card-body style="padding: 15px 20px;">
                        <div class="d-flex justify-content-between align-items-center" style="margin-bottom: 12px;">
                            <div style="font-size: 16px;"> {{ item.machine.name }} </div>
                            <div class="badge-state" :class="classBadgeOnState(getMinerState(item))"></div>
                        </div>

                        <div class="d-flex justify-content-between with-seperator">
                            <div class="text-muted text-uppercase">{{$t('tables.hashrate')}}</div>
                            <div>{{ item.hashrate }} <span class="text-muted">TH/s</span></div>
                        </div>
                        <div class="d-flex justify-content-between with-seperator">
                            <div class="text-muted text-uppercase">{{$t('tables.hashrate1h')}}</div>
                            <div>{{ item.hashrate1h }} <span class="text-muted">TH/s</span></div>
                        </div>
                        <div class="d-flex justify-content-between with-seperator">
                            <div class="text-muted text-uppercase">{{$t('tables.hashrate24h')}}</div>
                            <div>{{ item.hashrate24h }} <span class="text-muted">TH/s</span></div>
                        </div>
                        <div class="separator" style="margin: 8px 0;" />
                        <div class="d-flex justify-content-between">
                            <i18n path="tables.net-profit" class="text-muted text-uppercase" tag="div">
                            </i18n>
                            <div>{{ getValueByTicker(item.current_balance) }} <span class="text-muted">$</span></div>
                        </div>
                        <div class="separator" style="margin: 8px 0;" />
                        <div class="d-flex justify-content-between" style="padding: 4px 0;">
                            <div class="text-muted text-uppercase">{{$t('tables.energy-usage')}}</div>
                            <div>{{ item.machine.energy_usage }} <span class="text-muted">W</span></div>
                        </div>
                        <div class="separator" style="margin: 8px 0;" />
                        <div class="d-flex justify-content-between" style="padding: 4px 0;">
                            <div class="text-muted text-uppercase"></div>
                            <b-badge v-if="item.status === 0" variant="danger" style="font-size: 8px;">
                                {{$t('contracts.statuses.unpaid')}}
                            </b-badge>
                            <b-badge v-else-if="item.status === 1" variant="primary" class='badge-start-view'>
                                {{$t('contracts.statuses.start')}} {{ Utils.daysAgo( item.start_at ) }}
                            </b-badge>
                             <b-badge v-else-if="item.status === 3" variant="danger" style="font-size: 8px;">
                                {{$t('contracts.statuses.repair')}}
                            </b-badge>
                            <b-badge v-else-if="item.status === 5" variant="primary">
                              {{$t('contracts.statuses.payment-received')}}
                            </b-badge>
                            <span v-else-if="item.status === 2" class="text-ellipsis" style="font-size: 11px;">{{ item.serial_number }}</span>
                        </div>
                        
                    </b-card-body>
                </b-card>

            </div>
        </showAt>
        <showAt breakpoint="mediumAndAbove">
            <b-card no-body style="padding: 0; margin: 0;">
                <b-card-body style="padding: 30px 0px; padding-bottom: 12px;">
                    <div class="d-flex justify-content-end mb-1 mr-1">
                        <b-button-group>
                            <template v-for="(state, index) in states">
                                <b-button  @click="setState(state.value)" :variant="filter.state === state.value ? 'active' : 'inactive'" size="sm" class="d-flex align-items-center">
                                    <div v-if="state.value" class="badge-state" :class="classBadgeOnState(state.value)"></div>
                                    <span style="margin-right: 2px">{{ state.display }}</span>
                                    <feather-icon
                                        v-if="state.value"
                                        v-b-popover.hover.bottom="$t('popovers.miners-state.'+state.value)"
                                        class="cursor-pointer info-hover text-muted"
                                        icon="AlertCircleIcon"
                                        style="margin: 0px 3px"
                                    />
                                    <div class="miners-counter" v-if="state.value == 'active'">{{countActiveMiners}}</div>
                                    <div class="miners-counter" v-else-if="state.value == 'inactive'">{{countInactiveMiners}}</div>
                                    <div class="miners-counter" v-else-if="state.value == 'unstable'">{{countUnstableMiners}}</div>
                                    <div class="miners-counter" v-else>{{countTotalMiners}}</div>
                                </b-button>
                            </template>
                        </b-button-group> 
                    </div>

                    <b-tbody class='d-flex flex-column py-5' v-if='filterList.length === 0' >
                        <div class="d-flex flex-column justify-content-center">
                            <div class="nodata-icon ml-auto mr-auto"></div>
                            <p class="text-muted mt-1 ml-auto mr-auto text-center">
                                {{$t("contracts.no-data")}}
                            </p>
                        </div>
                    </b-tbody>

                    <b-table-simple v-else responsive >

                        <b-thead>
                            <b-tr>
                                <b-th class="text-nowrap">{{$t('tables.model')}}</b-th>
                                <b-th class="text-nowrap">{{$t('tables.hashrate')}}</b-th>
                                <b-th class="text-nowrap">{{$t('tables.hashrate1h')}}</b-th>
                                <b-th class="text-nowrap">{{$t('tables.hashrate24h')}}</b-th>
                                <b-th class="text-nowrap">{{$t('tables.serial-number')}}</b-th>
                            </b-tr>
                        </b-thead>

                        <b-tbody>
                            <b-tr v-for="(item, index) in filterList" :key="index" style="cursor: pointer;" @click="onSelect({ item: item })">
                                <b-th class="font-weight-bolder text-black text-ellipsis text-nowrap">
                                    <div class="d-flex align-items-center">
                                        <div class="badge-state" :class="classBadgeOnState(getMinerState(item))"></div>
                                        {{ item.machine.name }}
                                    </div>
                                </b-th>
                                
                                <b-th class="font-weight-normal text-black">{{ item.hashrate }} <span class="text-muted">TH/s</span></b-th>
                                <b-th class="font-weight-normal text-black">{{ item.hashrate1h }} <span class="text-muted">TH/s</span></b-th>
                                <b-th class="font-weight-normal text-black">{{ item.hashrate24h }} <span class="text-muted">TH/s</span></b-th>

                                <b-th class="font-weight-normal text-black overflow-hidden text-ellipsis">
                                    <span class="text-ellipsis" style="font-size: 11px;">{{ item.serial_number }}</span>
                                </b-th>
                            </b-tr>
                        </b-tbody>

                     </b-table-simple>
                </b-card-body>
            </b-card>
        </showAt>
    </div>
</template>

<script>
    
    import User from "@/modules/user/"
    import Utils from "@/modules/utils/"
    
    export default {

        data() {
            return {
                Utils,
                ticker: User.currency,

                states: [
                    { value: null, display: "Все" },
                    { value: "active", display: "Активные" },
                    { value: "unstable", display: "Нестабильные" },
                    { value: "inactive", display: "Неактивные" }
                ],

                filter: {
                    state: null
                },
            }
        },
        props: {
            contract: {
                type: Object,
                default: {miners: []}
            }
        },
        methods: {
            
            getValueByTicker( value ) {
                
                if( this.ticker === "BTC" ) {
                    return value.toFixed(12);
                }
                
                return (value * this.$blockchain.tickers.values[User.currency].last).formatMoney(2, "", " ");
                
            }, 
            
            onSelect( args ) {
              this.$router.push({
                  name: "user-contract-miner-details",
                  params: {
                      id: this.$route.params.id,
                      miner: args.item.guuid
                  },
                  props: {
                      contract: this.contract,
                      item: args.item
                  }
              });
            },

            getMinerState(miner) {
                if (miner.is_unstable == 0 && miner.status == 2) return 'active';
                else if (miner.is_unstable == 1 && miner.status == 2) return 'unstable';
                else return 'inactive';
            },
           
            setState( value ) {
                this.filter.state = value;
            },

            classBadgeOnState(state) {
                if (state == 'active') return 'state-cicle-active';
                else if (state == 'unstable') return 'state-cicle-unstable';
                else if (state == 'inactive') return 'state-cicle-inactive';
                else return '';
            }
        },
        computed: {
            countActiveMiners() {
                return this.contract.miners.filter(miner => miner.is_unstable == 0 && miner.status == 2).length;
            },
            countInactiveMiners() {
                return this.contract.miners.filter(miner => miner.status != 2).length;
            },
            countUnstableMiners() {
                return this.contract.miners.filter(miner => miner.is_unstable == 1 && miner.status == 2).length;
            },
            countTotalMiners() {
                return this.contract.miners.length;
            },
            
            filterList() {
                if (this.filter.state == 'active') return this.contract.miners.filter(miner => miner.is_unstable == 0 && miner.status == 2);
                else if (this.filter.state == 'inactive') return this.contract.miners.filter(miner => miner.status != 2);
                else if (this.filter.state == 'unstable') return this.contract.miners.filter(miner => miner.is_unstable == 1 && miner.status == 2);
                else return this.contract.miners;
            }
        },
        components: {

        },
        watch: {

        },
        mounted() {

        }

    }

</script>

<style lang="scss">
    .badge-state {
        width: 7px;
        height: 7px;
        border-radius: 50px;
        margin-right: 4px;
    }

    .miners-counter {
        background: #9CA2B1;
        border-radius: 30px;
        padding: 2px 4px 1px 4px;
        font-size: 9px;
        color: #FFF;
        line-height: 11px;
    }

    .state-cicle-active {
        // background-color: #46BCAA;
        background-color: #1FFF1A;
    }
    .state-cicle-unstable {
        // background-color: #FF6F71;
        background-color: #FF1A1A;
    }
    .state-cicle-inactive {
        border: solid 1px #9CA2B1;
    }
</style>