<template>

    <b-card class="m-0" no-body style="margin: 16px;">
        
        <b-row>
            <b-col cols="6">
                <b-card-body>
                    <p style="font-size: 16px; margin-bottom: 16px;"> {{$t('statistics.net-profit')}} <b-link @click="changeCurrency"> {{ ticker }}</b-link> </p>
                    <div class="d-flex" style="margin-bottom: 12px;">
                        <p style="font-size: 40px; font-weight: 600;">
                            {{ (contract.total_balance * $blockchain.tickers.values[ticker].last).formatMoney(ticker === "BTC" ? 4 : 2, ' ', ' ') }} {{ User.getSymbol() }} 
                        </p>
                    </div>
                    <span class="block-view">
                        {{ contract.ths.formatMoney(2,' ', ' ') }} TH/s <span class="text-muted" style="text-decoration: lowercase">{{$t('statistics.total-capacity')}}</span>
                    </span>
                </b-card-body>
            </b-col>
            <b-col cols="6">
                <b-card-body>
                    <div style="font-size: 14px; margin-bottom: 16px;">
                        <div class="d-flex justify-content-between" style="margin: 6px 0;">
                            <span class="text-muted font-weight-light">
                                {{$t('statistics.investments')}}
                            </span>
                            <span>
                               {{ contract.investments.formatMoney(0,' ',' ') }} $
                            </span>
                        </div>

                        <div class="d-flex justify-content-between" style="margin: 6px 0;">
                            <span class="text-muted font-weight-light">
                                {{$t('statistics.date-start')}}
                            </span>
                            <span v-if="contract.status === 6">
                                -
                            </span>
                            <span v-else>
                                {{ toTimeString(contract.start_at) }}
                            </span>
                        </div>


                        <div class="d-flex justify-content-between" style="margin: 6px 0;">
                            <span class="text-muted font-weight-light">
                                {{$t('statistics.date-end')}}
                            </span>
                            <span v-if="contract.status === 6">
                                -
                            </span>
                            <span v-else>
                                {{ toTimeString(contract.end_at) }}
                            </span>
                        </div>

                        <div class="d-flex justify-content-between" style="margin: 6px 0;">
                            <span class="text-muted font-weight-light">
                                {{$t('statistics.progress')}}
                            </span>
                            <div v-if="contract.status === 1">
                                <b-progress :max="100" height="12px" style="width: 70px; margin-left: auto; margin-right: 4px; margin-top: auto; margin-bottom: auto;">
                                    <b-progress-bar
                                    variant="primary"
                                    :value="getCurrentPercent()"
                                    v-b-tooltip.hover :title="`${$t('statistics.total-income')} ${(contract.total_balance * $blockchain.tickers.values['USD'].last).formatMoney(2, '', ' ')} USD `"
                                    label=" "
                                    />
                                </b-progress>
                                <span class="text-muted mt-auto mb-auto" style="font-size: 12px;">
                                    {{ getCurrentPercent().toFixed(1) }}%
                                </span>
                            </div>
                            <div v-else-if="contract.status === 0">
                                <b-badge variant="danger">{{$t('contracts.statuses.unpaid')}}</b-badge>
                            </div>
                            <div v-else-if="contract.status === 3">
                                <b-badge variant="primary" style="background-color: #E4EAFD; color: #426FF6; word-break: normal; word-wrap: break-word;">
                                    {{$t('contracts.statuses.start')}} {{ Utils.daysAgo( contract.start_at ) }}
                                </b-badge>
                            </div>
                            <div v-else-if="contract.status === 6">
                                <b-badge variant="secondary">
                                    {{$t('contracts.statuses.archive')}}
                                </b-badge>
                            </div>  
                        </div>
                    </div>


                </b-card-body>
            </b-col>
        </b-row>
        
        <b-card-body v-if="!contract.is_anonymous" >
            <b-button :href="doc_pdf" target="_blank" variant="outline-primary" size="lg" block class="btn-large">{{$t('contracts.btns.download-contract')}}</b-button>
        </b-card-body>

        <b-card-body v-if="contract.is_show_checklist == 1" >
                <b-button :href="checklist_pdf"  target="_blank" variant="primary" size="lg" block class="btn-large">{{$t('contracts.btns.download-check-list')}}</b-button>
        </b-card-body>
              
        
    </b-card>
    
</template>

<script>

    import User from "@/modules/user/"
    import Blockchain from "@/modules/blockchain"

    export default {

        data() {
            return {
                User,
                Blockchain
            }
        },
        props: {
            contract: {
                type: Object
            }
        },
        methods: {
            toTimeString( timestamp ) {
                
                let date = new Date(timestamp * 1000);
                return new Intl.DateTimeFormat('ru-RU', {
                    day: "2-digit",
                    month: '2-digit',
                    year: "numeric"
                }).format(date);
                
            },
            getCurrentPercent() {
                if( this.contract.type === 2 ) {
                    // let percent = ( (Math.floor(Date.now() / 1000)) / this.contract.end_at );
                    // return  100 - (percent * 100) ;
                    
                    return 100 * ((Math.floor(Date.now() / 1000)) - this.contract.start_at) / (this.contract.end_at - this.contract.start_at)
                }
                return ((this.contract.total_balance * this.$blockchain.tickers.values['USD'].last ) / this.getPeriodProfitUSD()) * 100;     
            },
            changeCurrency( event ) {
                event.stopPropagation();
                this.$router.push(`${this.$route.path}?act=currency`);
            },
            getPeriodProfitUSD() {
                return (((84600 * 30 * this.contract.period * User.contracts.values.R * ( User.contracts.values.H * this.contract.ths )) / ( User.contracts.values.D * Math.pow(2, 32) )) * this.$blockchain.tickers.values["USD"].last) ;                
            },

            getPeriodProfit() {
                return (((84600 * 30 * this.contract.period * User.contracts.values.R * ( User.contracts.values.H * this.contract.ths )) / ( User.contracts.values.D * Math.pow(2, 32) )) * this.$blockchain.tickers.values[this.ticker].last) ;                
            },
        },
        computed: {
            ticker() {
                return User.currency;
            },
            
            user() {
                return User;
            },

            doc_pdf() {
                return `${process.env.VUE_APP_BASE_URL}doc/${this.contract.doc}`
            },

            checklist_pdf() {
                return `${process.env.VUE_APP_BASE_URL}checklist/pdf`
            }
        },
        components: {

        },
        watch: {

        },
        mounted() {

        }

    }

</script>