<template>
    <b-overlay
        :show="is_loading"
        blur="50"
        variant='transparent'
        spinner-variant="primary"
        spinner-type="grow"
        spinner-small
        rounded="sm"
    >
        <b-card no-body>
            
            <b-card-body class="mb-0 pb-0" style="padding: 16px;">
                <b-row>
                    <b-col class="data-form">
                        <b-form-group>
                            <div class="d-flex justify-content-between">
                                <div style="flex-grow: 1">
                                    <date-range-picker
                                        ref="picker"
                                        :locale-data="$i18n.locale == 'ru' ? calendar_locale : calendar_en"
                                        :max-date="maxPickerDate"
                                        :singleDatePicker="false"
                                        :timePicker="false"
                                        :timePicker24Hour="true"
                                        :showWeekNumbers="false"
                                        :showDropdowns="false"
                                        :autoApply="false"
                                        :linkedCalendars="false"
                                        :ranges="false"
                                        v-model="filters.period"
                                        @update="get"
                                    >
                                        <template v-slot:input="picker" style="min-width: 350px;">
                                            <span v-if="picker.startDate">{{ picker.startDate | date }} - {{ picker.endDate | date }}</span>
                                            <span v-else class="text-muted font-weight-normal">{{$t('statistics.period')}}</span>
                                        </template>

                                    </date-range-picker>
                                </div>

                                <feather-icon icon="CalendarIcon" class="mt-auto mb-auto text-muted" size="18"  />

                            </div>
                        </b-form-group>
                    </b-col>


                </b-row>
            </b-card-body>

            <b-card-body class="data-form mb-0 pb-0" style="padding: 0 32px;">
                
                <b-row v-if="statistic" class="mb-2 filter-block">
                    
                    <b-col class="mt-auto mb-auto with-seperator" cols="4" style="border-right-width: 1px;">
                        <span class="text-muted">{{$t('statistics.total-profit')}}</span>
                        <p class="font-weight-bolder mb-0">
                            {{ getValueByTicker( statistic.summary.total_profit ) }} 
                            <span v-if="ticker !== 'BTC'">{{ User.getSymbol() }}</span> 
                            <span v-else>BTC</span>
                        </p>
                    </b-col>
                    
                    <b-col class="mt-auto mb-auto with-seperator" cols="4" style="border-right-width: 1px;">
                        <span class="text-muted">{{$t('statistics.net-profit')}}</span>
                        <p class="font-weight-bolder mb-0">
                            {{ getValueByTicker( statistic.summary.current_profit ) }} 
                            <span v-if="ticker !== 'BTC'">{{ User.getSymbol() }}</span> 
                            <span v-else>BTC</span>
                        </p>
                    </b-col>
                    
                    <b-col class="mt-auto mb-auto with-seperator" cols="4" style="border-right-width: 1px;">
                        <span class="text-muted">{{$t('statistics.profit24')}}</span>
                        <p class="font-weight-bolder mb-0">
                            {{ getValueByTicker(  statistic.summary.daily_profit ) }} 
                            <span v-if="ticker !== 'BTC'">{{ User.getSymbol() }}</span> 
                            <span v-else>BTC</span>
                        </p>
                    </b-col>
                
                </b-row>
                                
            </b-card-body>

            <b-card-body class="pt-0" style="padding: 15px 20px; ">
                <div style="font-size: 12px;">
                    <span class="text-muted" style='margin-right: 6px;'>{{$t('statistics.grouping')}}</span>
                    <b-dropdown
                        variant="muted"
                        size="sm"
                        right
                        no-caret
                        class="group-picker"
                      >

                        <template #button-content>
                            <div class="d-flex">
                                <span class="text-primary mb-auto mb-auto font-weight-normal" style="font-size: 12px;">{{ $t(filters.group.display) }}</span>
                                <feather-icon icon="ChevronDownIcon" class="text-primary" style="margin: auto 2px;" />
                            </div>
                        </template>

                        <b-dropdown-item 
                            v-for="(group, index) in grouping" 
                            :key="index"
                            @click="filters.group = group"
                            class="text-black"
                       >
                            <div class="d-flex align-items-center">
                                <b-form-checkbox size="md" :checked="filters.group.value === group.value" /> 
                                <div class="mt-auto mb-auto">{{ $t(group.display) }}</div>
                            </div>
                        </b-dropdown-item>
                      
                    </b-dropdown>
                </div>
            </b-card-body>

            <div>

                 <b-row>

                    <b-col v-if="statistic">
                                                

                        <b-table-simple id="table-detalization" responsive >

                            <b-thead>
                                <b-tr>
                                    <b-th>{{$t('tables.time')}}</b-th>
                                    <b-th v-if="contract.type === 2">{{$t('tables.miner')}}</b-th>
                                    <b-th>{{$t('tables.total-profit')}}</b-th>
                                    <b-th>
                                        <i18n path="tables.net-profit" tag="span">
                                        </i18n>
                                    </b-th>
                                    <b-th>{{$t('tables.energy')}}</b-th>
                                    <b-th>{{$t('tables.complexity-network')}}</b-th>
                                </b-tr>
                            </b-thead>

                            <b-tbody v-for="(section, sectionIndex) in statistic.sections" :key="sectionIndex" style="border-bottom: none; border-top: none; padding: 0; margin: 0;">

                                <b-tr style='border-top: none;' variant="gray">
                                  <b-th class="text-black" :colspan="contract.type === 2 ? 6 : 5">{{ section.name }}</b-th>
                                </b-tr>

                                <template v-for="(level,levelIndex) in section.levels">


                                    <b-tr v-for="(item, index) in level.items" :key="`${levelIndex}:${index}`">

                                        <b-th v-if="index === 0" class="text-black font-weight-bolder align-top" :rowspan="level.items.length" style="border-right: 1px solid #EBECF2;">
                                            <span> {{ level.name }} </span>
                                        </b-th>
                                        
                                        <b-th v-if="contract.type === 2" class="text-black font-weight-normal text-nowrap">
                                            <b-link :to="toMiner(item.miner)" class="m-0 font-weight-bolder">{{ item.miner.machine.name }}</b-link>
                                            <div class="d-flex" style="margin-top: 6px;">
                                                <div style="margin-right: 16px; font-size: 10px;">{{ item.miner.machine.hashrate }} <span class="text-muted">TH/s</span></div>
                                                <div style="margin-right: 16px; font-size: 10px;">{{ item.miner.machine.energy_usage }} <span class="text-muted">W</span></div>
                                                <div style="margin-right: 16px; font-size: 10px;">{{ item.miner.machine.energy_performance }} <span class="text-muted">J/TH</span></div>
                                            </div>
                                        </b-th>
                                        
                                        <b-th class="text-black font-weight-normal text-nowrap"> 
                                            {{ getEnergyByTicker(item.profit + item.energy) }} 
                                            <span v-if="ticker !== 'BTC' " class="text-muted">{{ User.getSymbol() }}</span> 
                                            <span v-else class="text-muted">BTC</span>
                                        </b-th>
                                        
                                        
                                        <b-th class="text-black font-weight-normal text-nowrap"> 
                                            {{ getValueByTicker(item.profit) }} 
                                            <span v-if="ticker !== 'BTC' " class="text-muted">{{ User.getSymbol() }}</span> 
                                            <span v-else class="text-muted">BTC</span>
                                        </b-th>
                                        
                                        
                                        <b-th class="text-black font-weight-normal text-nowrap"> 
                                            {{ getEnergyByTicker(item.energy) }} 
                                            <span v-if="ticker !== 'BTC' " class="text-muted">{{ User.getSymbol() }}</span> 
                                            <span v-else class="text-muted">BTC</span>
                                        </b-th>
                                        
                                        <b-th class="text-black font-weight-normal text-nowrap"> {{ item.difficult.formatMoney(0, "", " ") }} </b-th>

                                   </b-tr>



                                </template>


                            </b-tbody>

                        </b-table-simple>
                        
                        <template v-if="statistic.sections.length === 0">
                            <div class="d-flex flex-column justify-content-center" style="padding: 16px;">
                                <div class="nodata-icon ml-auto mr-auto"></div>
                                <p class="text-muted mt-1 ml-auto mr-auto text-center">
                                    {{$t('tables.no-data')}}
                                </p>
                            </div>
                        </template>

                        <b-card-body v-if="statistic.total_count > 0" class="p-0 d-flex">
                            <div class="ml-auto mr-auto">
                                 <b-pagination-nav :value="statistic.current_page" :link-gen="linkGen" :number-of-pages="statistic.last_page" use-router replace ></b-pagination-nav>
                            </div>
                        </b-card-body>

                    </b-col>

                </b-row>

            </div>
        </b-card>
    </b-overlay>
        
</template>

<script>
    
    import User from "@/modules/user/"

    import DateRangePicker from 'vue2-daterange-picker'
    import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'

    export default {

        data() {
            return {
                
                User,
                
                is_loading: false,
                
                ticker: User.currency,
                
                maxPickerDate: new Date(),
                
                calendar_locale: {
                    direction: 'ltr',
                    format: 'mm/dd/yyyy',
                    separator: ' - ',
                    applyLabel: 'Применить',
                    cancelLabel: 'Отмена',
                    weekLabel: 'Н',
                    customRangeLabel: 'Период',
                    daysOfWeek: ['Вс', 'Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб'],
                    monthNames: ['Янв', 'Фев', 'Мар', 'Апр', 'Май', 'Июн', 'Июл', 'Авн', 'Сен', 'Окт', 'Нов', 'Дек'],
                    firstDay: 0
                },

                calendar_en: {
                    direction: 'ltr',
                    format: 'mm/dd/yyyy',
                    separator: ' - ',
                    applyLabel: 'Apply',
                    cancelLabel: 'Cancel',
                    weekLabel: 'Н',
                    customRangeLabel: 'Period',
                    daysOfWeek: ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'],
                    monthNames: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
                    firstDay: 0
                },
                
                filters: {
                    period:{
                        startDate: null,
                        endDate: null
                    },
                    group: {
                        display: "statistics.grouping-type.day",
                        value: "day"
                    }
                },
                
                statistic: null,
                
                grouping: [
                    { value: "day", display: "statistics.grouping-type.day" },
                    { value: "week", display: "statistics.grouping-type.week" },
                    { value: "month", display: "statistics.grouping-type.month" },
                    { value: "", display: "statistics.grouping-type.without" },
                ],
                
            }
        },
        props: {
            contract: {
                type: Object
            }
        },
        methods: {
            
            
            toMiner(miner) {
                
                return {
                  name: "user-contract-miner-details",
                  params: {
                      id: this.$route.params.id,
                      miner: miner.guuid
                  },
                  props: {
                      contract: this.contract,
                      item: miner
                  }
              }
                
            },
            
            getEnergyByTicker( value ) {
                
                if( this.ticker === "BTC" ) {
                    return value.toFixed(12);
                }
                
                return (value * this.$blockchain.tickers.values[User.currency].last).formatMoney(4, "", " ");
                
            },
            
            getValueByTicker( value ) {
                
                if( this.ticker === "BTC" ) {
                    return value.toFixed(12);
                }
                
                return (value * this.$blockchain.tickers.values[User.currency].last).formatMoney(2, "", " ");
                
            },
            
            setPeriod(  value ) {
                this.filter.period = value;
                this.updateStatistic();
            },
            
            updateStatistic() {
                
                this.is_loading = true;
                
                this.$request.get('contracts.getStatistic', {
                    "for": this.contract.id,
                    period: this.filter.period
                }).then( statistic => {
                    this.statistic = statistic;
                    this.is_loading = false;
                });
                
            },
            
            linkGen( pageNum ) {
              return `${this.$route.path}?page=${pageNum}`;                  
            },
            
            handleScroll( e ) {
                
                if(!this.statistic) return;
                if(!this.statistic.has_more_pages) return;
                if( this.is_loading ) return;
                
                let el = e.target;
               
                if( el.scrollTop + el.offsetHeight > ( el.scrollHeight - 255 ) ) {
                    
                  let params = this.params;
                  
                  params['page'] = this.statistic.current_page + 1;
                  
                  this.is_loading = true;
                  
                  this.$request.get("contracts.getStatistic", params ).then( result => {
                       this.statistic.has_more_pages = result.has_more_pages;
                       this.statistic.current_page = result.current_page;
                       this.statistic.sections.push( ...result.sections );
                       this.is_loading = false;                       
                  });
                  
                }
                
            },
            
            get() {
                this.$request.get('contracts.getStatistic', this.params ).then( statistic => {
                     this.statistic = statistic;
                });
            },
        },
        
        computed: {
            params() {
                
                let period = null;                
                
                if( this.filters.period.startDate && this.filters.period.endDate ) {
                    period = {};
                    period['start'] = this.filters.period.startDate  ;
                    period['end'] = this.filters.period.endDate  ;
                }
                
                let params = {
                    for: this.contract.id,
                    period: period,
                    group: this.filters.group.value,
                }
                
                
                if( this.$route.query.page ) {
                    params['page'] = this.$route.query.page;
                }
                
                return params;
            }
        },
        
        filters: {
            date(val) {
              return val ? new Intl.DateTimeFormat('ru', { day: 'numeric',  year: '2-digit', month: 'numeric' }).format(val) : ''
            }
        },
        
        watch: {
            
            "filters.group" : function() {
                this.get();
            },
            
            "User.currency" : function() {
                this.ticker = User.currency;
            },
            
            "$route.query.page" : function( page ) {
                this.get();
                
            }
        },
        components: {
            DateRangePicker
        },
      
        mounted() {
            
            this.get();
            
        }

    }

</script>